import React, { useCallback } from 'react'
import {
  FEATURE_TOGGLES,
} from '@/services/Configuration'
import {
  isPromoValid, promoCode, promoLocallyEnabled, promoText,
} from '@/services/promoCodes/promo'
import dynamic from 'next/dynamic'
import type { MinPLPProductInfo } from '@/types/PLPProducts'
import { addParamsToUrl } from '@/helpers/addParamsToUrl'
import { GraphQlCustomer } from '@/helpers/graphql'
import { sendDMPAnalyticsEvent } from '@/hooks/DmpAnalytics/pushDMPAnalyticsEvent'

const Row = dynamic(import('@csc/dls/Row'))
const ProductTile = dynamic(import('@/components/dls/productTiles/ProductTile'))
const AddToWishList = dynamic(import('@/components/Wishlists/AddToWishList'))
interface PLPProductsProps {
  products: readonly MinPLPProductInfo[],
  cols: number,
  isFreeRugDoorstepShippingOn: boolean,
  user: GraphQlCustomer | null,
  reloadCustomer: () => (void | Promise<void>),
  plpSource: 'search' | 'category' | 'collection' | 'brand' | 'promo',
  plpSourceId: string,
  pageNumber: number,
}

const PLPProducts: React.FC<PLPProductsProps> = ({
  products,
  cols = 3,
  isFreeRugDoorstepShippingOn,
  user,
  reloadCustomer,
  plpSource,
  plpSourceId,
  pageNumber,
}) => {
  const isPromoEnabled = promoLocallyEnabled
  const isTurnToEnabled = FEATURE_TOGGLES?.isTurnToEnabled

  const getPromoData = useCallback((skus: string[]) => (
    isPromoEnabled() && skus.some(isPromoValid) ? {
      text: promoText(),
      code: promoCode(),
    } : undefined
  ), [isPromoEnabled])

  const getCouponFromProduct = (product: MinPLPProductInfo) => {
    const result = product.prices.variants.find((v) => (
      !!v.promoMessage
      && !!v.couponCode
    )) || {
      promoMessage: '',
      couponCode: '',
    }
    if (result.promoMessage && result.couponCode) {
      return {
        text: result.promoMessage,
        code: result.couponCode,
      }
    }
    return undefined
  }
  return (
    <Row cols={{ xs: 2, md: cols }} gapY="xl" gapX={{ xs: 'md', md: 'xl' }}>
      {products.map((product, index) => (
        <div
          key={product.sku}
          className="relative"
        >
          <ProductTile
            imageBottomRightComponent={(
              <AddToWishList
                className="border max-h-56 overflow-y-scroll overflow-x-hidden -left-1/2 -translate-x-1/2 -top-6 absolute transform -translate-y-full bg-white rounded-md z-1002"
                user={user}
                variant={{
                  productId: product.id,
                  id: product.variants?.[0]?.id,
                  sku: product.variants?.[0]?.sku,
                }}
                reloadCustomer={reloadCustomer}
              />
            )}
            lazyLoad={index > 6}
            onImpressionEvent={() => {
              sendDMPAnalyticsEvent({
                eventType: 'plp-impression',
                items: [{
                  salePrice: product.variants?.[0]?.sale_price,
                  price: product.variants?.[0]?.price,
                  sku: product.sku,
                  quantity: 1,
                }],
                context: [{
                  key: 'plp_source',
                  value: {
                    stringValue: plpSource,
                    numberValue: 0,
                    booleanValue: false,
                  },
                }, {
                  key: 'plp_source_id',
                  value: {
                    stringValue: plpSourceId,
                    numberValue: 0,
                    booleanValue: false,
                  },
                }, {
                  key: 'plp_page',
                  value: {
                    stringValue: String(pageNumber),
                    numberValue: 0,
                    booleanValue: false,
                  },
                }, {
                  key: 'plp_index',
                  value: {
                    stringValue: String(index),
                    numberValue: 0,
                    booleanValue: false,
                  },
                }],
              })
            }}
            product={{
              ...product,
              custom_url: {
                url: addParamsToUrl(product.custom_url.url, {
                  sku: product.variants?.[0]?.sku,
                  plp_source: plpSource,
                  plp_source_id: plpSourceId,
                  plp_page: String(pageNumber),
                  plp_index: String(index),
                }),
              },
              is_free_shipping_eligible: (
                isFreeRugDoorstepShippingOn
                && !product.hasDoorstepEligibleRug
                && product.allVariantsAreDSElligibleRugs
              ),
              FreeShipping: (
                product.FreeShipping
                || (
                  isFreeRugDoorstepShippingOn
                  && product.hasDoorstepEligibleRug
                )
              ),
            }}
            changeImageOnHover
            promoData={
              getPromoData(product.prices.variants.map((v) => v.sku))
              || getCouponFromProduct(product)
            }
            reviewCount={isTurnToEnabled ? product.reviewCount || 0 : 0}
            reviewRating={product.reviewRating}
          />
        </div>
      ))}
    </Row>
  )
}

export default PLPProducts
