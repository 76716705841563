import { log, error } from '@/services/Log'
import {
  object,
  CheckerReturnType,
  number,
  string,
  array,
  bool,
  nullable,
  assertion,
} from '@recoiljs/refine'
import {
  COGNITO_REGION,
  COGNITO_IDENTITY_POOL_ID,
  FIREHOSE_REGION,
  FIREHOSE_DELIVERY_STREAM_NAME,
} from '@/services/Configuration'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { thenCaught } from '@/helpers/FP'
import { getComposeAssignmentMap } from '@/helpers/getABExperiment'

const Event = object({
  uuid: string(),
  userUUID: string(),
  date: string(),
  eventType: string(),
  url: string(),
  items: array(object({
    sku: string(),
    salePrice: number(),
    price: number(),
    quantity: number(),
  })),
  context: array(
    object({
      key: string(),
      value: object({
        stringValue: nullable(string()),
        numberValue: nullable(number()),
        booleanValue: nullable(bool()),
      }),
    }),
  ),
})

const assertEvent = assertion(Event, 'Invalid DMP Analytics Event')

export type DMPAnalyticsEvent = CheckerReturnType<typeof Event>
export type DMPAnalyticsEventContext = DMPAnalyticsEvent['context']

type FirehoseClient = InstanceType<Awaited<typeof import('@aws-sdk/client-firehose')>['FirehoseClient']>

let client: null | FirehoseClient = null

type MinEventData = Omit<DMPAnalyticsEvent, 'uuid' | 'userUUID' | 'date' | 'url'> & {
  uuid?: DMPAnalyticsEvent['uuid']
  url?: DMPAnalyticsEvent['url']
}

const localStorageDMPUserUUIDKey = 'dmpUserUUID'
const loadUserUUID = (uuidGenerator: () => string) => {
  const fromLocalStorage = localStorage.getItem(localStorageDMPUserUUIDKey)
  if (fromLocalStorage) {
    return fromLocalStorage
  }
  const generated = uuidGenerator()
  localStorage.setItem(localStorageDMPUserUUIDKey, generated)
  return generated
}

const sendEventHappyPath = async (event: MinEventData) => {
  const [
    { FirehoseClient, PutRecordBatchCommand },
    { fromCognitoIdentityPool },
    { v4: uuidv4 },
  ] = await allPromisesWithRetries(() => [
    import('@aws-sdk/client-firehose'),
    import('@aws-sdk/credential-providers'),
    import('uuid'),
  ])
  const eventData = assertEvent({
    url: window.location.href,
    uuid: String(event.uuid || uuidv4?.()),
    userUUID: loadUserUUID(uuidv4),
    date: new Date().toISOString(),
    ...event,
    context: [
      ...event.context,
      {
        key: 'A/B tests',
        value: { stringValue: JSON.stringify(getComposeAssignmentMap()) },
      },
    ],
  })

  log('sendPageView: Firehose client loaded')
  client = client || new FirehoseClient({
    region: FIREHOSE_REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: COGNITO_REGION },
      identityPoolId: COGNITO_IDENTITY_POOL_ID,
    }),
  })
  log('sendPageView: Firehose client created')
  if (client) {
    log('Sending page view')
    await client.send(new PutRecordBatchCommand({
      DeliveryStreamName: FIREHOSE_DELIVERY_STREAM_NAME,
      Records: [
        {
          Data: Buffer.from(JSON.stringify(eventData)),
        },
      ],
    }))
    log('sendPageView: Page view sent')
  }
}

const sendEventSadPath = (err: unknown) => {
  error(`sendDMPAnalyticsEvent: Error sending event: ${String(err)}`)
}

export const sendDMPAnalyticsEvent = thenCaught(sendEventHappyPath)(sendEventSadPath)
